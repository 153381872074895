.grid-container {
  margin-right: 20vw;
  margin-left: 20vw;
}

.skill {
  background-color: transparent;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray);
  position: relative;
  transition: background-color 0.2s ease-in-out;
}

.skill:hover {
  transform: translateY(-1px);
}
.skill p {
  margin: 0px;
}
h3 {
  margin-bottom: 5px;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
}
