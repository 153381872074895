.portfolio_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 8px;
  padding: 8px;
}

.portfolio_card {
  cursor: pointer;
  transition: all 1s ease-in-out;
  gap: 10px;
}

.portfolio_card:hover {
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-2.5px);
}

.portfolio_card_image {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.portfolio_card_button {
  height: auto;
  text-align: center;
}
.card_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -12px;
}
.card_text h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  color: darkslategray;
}
.card_text p {
  margin-top: 2px;
  font-size: 11px;
  margin-bottom: 0;
  color: gray;
}

/*Small Size*/
@media screen and (max-width: 600px) {
  .card_text p {
    font-size: 11px;
  }
}
