/*Small Size*/
@media screen and (max-width: 600px) {
  .btn {
    height: auto;
    padding: 2px;
  }
  .cta {
    gap: 5px;
  }

  .cta a {
    font-size: 12px;
  }
}
