.contact_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  width: 100%;
}

.grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  width: 60%;
  border-radius: 30px;
  height: 100%;
}
.vl {
  border-left: 1px solid var(--color-gray);
  left: 50%;
  margin-left: -3px;
  top: 0;
  height: 80%;
}

.box .btn {
  margin-top: 10%;
  align-items: center;
}

.contact_container h2 {
  align-self: center;
  justify-self: center;
}

.box {
  display: flex;
  flex-direction: column;
  color: var(--color-gray);
  justify-content: center;
  align-items: flex-start;
  margin-right: 10%;
  margin-left: 10%;
}

.bold {
  font-weight: 500;
  font-size: larger;
  color: var(--color-dark-gray);
}

.medium {
  font-size: medium;
  color: var(--color-dark-gray);
}

/* Small Size */
@media screen and (max-width: 768px) {
  .grid {
    width: 100%;
  }
  .box {
    margin-right: 5%;
    margin-left: 5%;
    align-items: center;
  }

  .bold {
    font-size: medium;
  }

  .medium {
    font-size: small;
  }
  .contact_container {
    height: 150px;
  }
}
