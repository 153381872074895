.item_container {
  flex: auto;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.top_text {
  margin-bottom: 7px;
  margin-left: 6vh;
}
.item_container h2 {
  font-family: "Cairo", sans-serif;
  font-size: xx-large;
  line-height: 40px;
}
.close_button {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 0;
  border-bottom: 1px;
}

.close_button .MuiButtonBase-root {
  padding: 0px;
}
.close_button .medium {
  text-decoration: dashed;
}
.close_button .MuiIconButton-root:hover {
  background-color: transparent;
  color: var(--color-primary-variant);
}
.close_icon {
  color: var(--color-secondary);
}
.socials {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-left: 6vh;
}
.button {
  display: flex;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 5px;
  height: 35px;
  margin-bottom: 0;
}

.button:hover {
  border-color: var(--color-primary-pale);
}

.button h4 {
  font-size: medium;
  text-align: center;
  margin-bottom: 0px;
  margin: 0;
}

.button .icon {
  color: var(--color-primary);
}

.item_container p {
  margin: 0;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 6vh;
}

.top_text .bold {
  font-family: "Cairo", sans-serif;
  font-size: large;
  font-weight: bold;
  line-height: 1px;
}

.medium {
  font-size: medium;
}
.descriptions {
  white-space: pre-wrap;
  margin-top: 20px;
  line-height: auto;
}

.item_container h2 {
  margin: 0;
  margin-top: 10px;
  margin-left: 6vh;
}
.image-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.image-row .portfolio_image {
  width: 18%;
  margin: 3%;
}
.image-row .double_image {
  width: 43%;
  margin: 1%;
  border-radius: 15px;
}

.image-row .landscape_image {
  width: 43.5%;
  margin-bottom: 1%;
  margin-top: 1%;

  border-radius: 15px;
}
.image-row .portfolio_video {
  width: 47%;
  margin: 1%;
}
.media {
  justify-content: center;
  align-items: center;
}
/* Small Size */
@media screen and (max-width: 768px) {
  .image-row .portfolio_image {
    width: 98%;
    margin: 1%;
  }
  .image-row .double_image {
    width: 98%;
    margin: 1%;
  }
  .image-row .portfolio_video {
    width: 98%;
    margin: 1%;
  }
  .descriptions {
    margin-top: 40px;
  }
}
