@import url("https://fonts.googleapis.com/css2?family=Saira+Semi+Condensed:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap");
:root {
  --color-bg: #8b919c;
  --color-bg-variant: #abb1bc;
  --color-primary: #f56300;
  --color-primary-variant: #ff8400;
  --color-primary-pale: #f9d8b5;
  --color-secondary: #0066cc;
  --color-secondary-variant: #2887e5;
  --color-white: #eeeeee;
  --color-black: #111111;
  --color-dark-gray: #424245;
  --color-gray: #9f9f9f;
  --color-light: #f5f5f7;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 95%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Saira Semi Condensed", sans-serif;
  background: var(--color-white);
  color: var(--color-dark-gray);
  font-size: 16px;
}

/* General Styles */

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 3rem;
  margin-top: 0;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.15rem;
  margin-bottom: 10px;
}

h5 {
  font-size: 1rem;
}

.text-light {
  color: var(--color-gray);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
  text-decoration: none;
}
p {
  text-align: justify;
  margin-top: 7px;
  margin-bottom: 7px;
}
i {
  text-align: justify;
  margin: 7px;
}

a:hover {
  color: var(--color-primary-variant);
  text-decoration: underline;
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.25rem 0.5rem;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  margin-bottom: 1rem;
}

.btn:hover {
  color: var(--color-primary-variant);
  border-color: var(--color-primary-pale);
}
.btn-primary:hover {
  background: var(--color-secondary-variant);
  color: var(--color-white);
  border-color: var(--color-secondary-variant);
}

.btn-primary {
  background: var(--color-secondary);
  color: var(--color-white);
  border: 1px solid var(--color-secondary);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}
