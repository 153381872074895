.tab_container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
}

.tab_left {
  width: 15%;
  align-self: flex-start;
}

.tab_right {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.tab-text {
  text-orientation: sideways-right;
}

.tabpanel_container {
  display: flex;
  width: 100%;
}

.tabpanel_container > div {
  flex-grow: 1;
}

.tabpanel_container > div > div {
  height: 100%;
}
.main_tabs .MuiTabs-indicator {
  background-color: var(--color-secondary);
}
.main_tabs .MuiTab-root.Mui-selected {
  color: var(--color-secondary);
}
.main_tabs .MuiButtonBase-root {
  font-family: "Cairo", sans-serif;
  font-size: medium;
}

/* Small Size */
@media screen and (max-width: 768px) {
  .tab_left {
    width: 100%;
  }
  .tab_container {
    flex-direction: column;
  }
  .tab_right {
    width: 100%;
  }
}
