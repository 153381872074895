.about-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 10%;
}

.about-container h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .about-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
