.experience-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  width: 100%;
  padding: 10px;
}

.experience-container h4 {
  text-decoration: underline;
  text-decoration-color: var(--color-gray);
}
.item_container {
  width: auto;
}

.experience-container p {
  text-align: left;
  margin-left: 3ch;
}

.headers {
  display: flex;
  flex-direction: row;
}
.vl {
  border-right: 0.1px solid var(--color-gray);
  height: 100%;
  justify-self: center;
  align-self: center;
}
.exp_contents {
  display: flex;
  flex-direction: row;
  margin-left: 150px;
  margin-right: 150px;
}
/* Small Size */
@media screen and (max-width: 768px) {
  .experience-container h3 {
    height: 70px;
  }
  .experience-container p {
    text-align: left;
  }
  .exp_contents {
    gap: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
