.tab_item {
  width: auto;
}
.tabs_holder .Mui-selected.tab-item {
  color: var(--color-primary);
}
.portfolio_cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80vw;
  margin: 1rem;
  gap: 1vw;
}
.grid_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.portfolio_image {
  margin-top: 5px;
  border-radius: 10px;
}
.dialog_holder {
  border-radius: 5vw;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.button {
  display: flex;
  align-items: center;
  width: max-content;
  height: 50px;
  color: var(--color-primary);
  padding: 0.1rem 0.1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}
.row h3 {
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: 0;
}
.row h4 {
  font-size: 1.2rem;
  margin-bottom: -1px;
  margin-top: 0;
}
.row p {
  margin-bottom: 0;
  margin-top: 0;
}
.media {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pdf_display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-width: 2px;
}
.social_links {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.dialog_content .MuiDialog-paper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabs_holder {
  width: 100%;
  align-self: center;
}
.fullHeight {
  max-width: 90%;
  justify-content: center;
  padding-left: 5%;
  background-color: var(--color-white);
}
.box_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.fullHeight .MuiDialog-paper {
  height: 100%;
  max-height: 100%;
  border-radius: 2vw;
  justify-content: center;
  align-content: center;
}
.tabs_holder .MuiTabs-indicator {
  background-color: var(--color-primary);
}
.tabs_holder .MuiButtonBase-root {
  font-family: "Cairo", sans-serif;
  font-size: medium;
}
/* Small Size */
@media screen and (max-width: 768px) {
  .portfolio_cards_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }
  .fullHeight .MuiDialog-paper {
    border-radius: 0px;
    background-color: var(--color-white);
  }
  .fullHeight {
    max-width: 100%;
    padding-left: 0;
  }
}
